<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Basic Information') }}
      </h3>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Travel Agency Booking Number')"
      name="agencyBookingNumber"
    >
      <a-input v-model:value="form.agencyBookingNumber" :size="size" />
    </a-form-item>
    <a-form-item
      v-if="value.property.anshinPropertyId"
      class="column is-12"
      :label="$t('Anshin Reservation ID')"
      name="agencyBookingNumber"
    >
      <a-input v-model:value="form.reservationId" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check In Date')" name="checkin">
      <a-date-picker
        v-model:value="form.checkin"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check In Time')" name="checkinTime">
      <a-time-picker
        v-model:value="form.checkinTime"
        value-format="HH:mm:ss"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check Out Date')" name="checkout">
      <a-date-picker
        v-model:value="form.checkout"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Travel Agency Booking Date')"
      name="agencyBookingDate"
    >
      <a-date-picker
        v-model:value="form.agencyBookingDate"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Nights')" name="nights">
      <a-input v-model:value="form.nights" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Travel Agency Booking Time')"
      name="agencyBookingTime"
    >
      <a-time-picker
        v-model:value="form.agencyBookingTime"
        value-format="HH:mm:ss"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Package Plan Name')" name="packagePlanName">
      <a-input v-model:value="form.packagePlanName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Package Plan Code')" name="packagePlanCode">
      <a-input v-model:value="form.packagePlanCode" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Guest Or Group Name Single Byte')"
      name="guestNameSingleByte"
    >
      <a-input v-model:value="form.guestNameSingleByte" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Guest Or Group Name Double Byte')"
      name="guestNameDoubleByte"
    >
      <a-input v-model:value="form.guestNameDoubleByte" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Guest Or Group Name Kanji Name')"
      name="guestNameKanji"
    >
      <a-input v-model:value="form.guestNameKanji" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Other Service Information')"
      name="otherServiceInfo"
    >
      <a-input v-model:value="form.otherServiceInfo" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Original Message')" name="originalMessage">
      <a-input v-model:value="form.originalMessage" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Specific Meal Condition')"
      name="specificMealCondition"
    >
      <a-input v-model:value="form.specificMealCondition" :size="size" />
    </a-form-item>
    <a-form-item
      v-if="reservationId"
      class="column is-12"
      :label="$t('Departure Date')"
      name="leaveDate"
    >
      <a-date-picker
        v-model:value="form.leaveDate"
        value-format="YYYY-MM-DD"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item
      v-if="reservationId"
      class="column is-12"
      :label="$t('Temporary Key Code')"
      name="temporaryKeyCode"
    >
      <a-input v-model:value="form.temporaryKeyCode" :size="size" />
    </a-form-item>
    <a-form-item
      v-if="reservationId"
      class="column is-12"
      :label="$t('Smartlock Key Code')"
      name="smartlockKeyCode"
    >
      <a-input v-model:value="form.smartlockKeyCode" :size="size" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import edit from '@/views/reservations/mixins/edit';

dayjs.extend(customParseFormat);

export default {
  name: 'EditBasicInfo',
  mixins: [edit],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      reservationId: undefined,
      form: {
        reservationId: undefined,
        agencyBookingNumber: undefined,
        agencyBookingDate: undefined,
        agencyBookingTime: undefined,
        checkin: undefined,
        checkout: undefined,
        checkinTime: undefined,
        nights: undefined,
        specificMealCondition: undefined,
        originalMessage: undefined,
        otherServiceInfo: undefined,
        guestNameSingleByte: undefined,
        guestNameDoubleByte: undefined,
        guestNameKanji: undefined,
        packagePlanCode: undefined,
        packagePlanName: undefined,
        smartlockKeyCode: undefined,
        temporaryKeyCode: undefined,
        leaveDate: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  computed: {
    checkinTimeStr() {
      if (this.form.checkinTime) return dayjs(this.form.checkinTime, 'HH:mm:ss').format('HH:mm:ss');
      return null;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        const { checkin, checkout } = nv;
        const details = nv.details || {};
        const basicInfo = details.basicInfo || {};
        const specificKeys = ['smartlockKeyCode', 'leaveDate', 'temporaryKeyCode', 'reservationId'];

        this.reservationId = details.reservationId;

        keys.forEach((k) => {
          if (k === 'checkin') this.form[k] = checkin;
          else if (k === 'checkout') this.form[k] = checkout;
          else if (specificKeys.includes(k)) this.form[k] = details[k];
          else this.form[k] = basicInfo[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        const {
          leaveDate, smartlockKeyCode, temporaryKeyCode, checkin, checkout, reservationId, ...rest
        } = this.form;
        const payload = {
          details: {
            leaveDate,
            smartlockKeyCode,
            temporaryKeyCode,
            reservationId,
          },
          checkinCheckout: {
            checkin,
            checkout,
          },
          ...rest,
        };
        await this.editDetails(this.value, payload, 'basicInfo');
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Basic Information": "Edit Basic Information"
  },
  "ja": {
    "Edit Basic Information": "基本宿泊情報を編集"
  }
}
</i18n>
