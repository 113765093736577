<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Other Service Information') }}
      </h3>
    </div>
    <a-form-item class="column is-12" name="otherServiceInfo">
      <a-textarea
        v-model:value="form.otherServiceInfo"
        placeholder="Other service information"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-form-item>
    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Other Service Information": "Edit Other Service Information"
  },
  "ja": {
    "Edit Other Service Information": "その他のサービス情報の編集"
  }
}
</i18n>

<script>
import edit from '@/views/reservations/mixins/edit';

export default {
  name: 'EditOtherServiceInfo',
  mixins: [edit],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      size: 'large',
      loading: false,
      form: {
        otherServiceInfo: undefined,
      },
      rules: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv && nv.details) {
          this.form = nv.details.basicInfo || {};
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        await this.editDetails(this.value, this.form, 'basicInfo');
        this.$emit('close');
      } catch (error) {
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
