<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Basic Information') }}
      </h3>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Travel Agency Booking Number')"
      name="agencyBookingNumber"
    >
      <a-input v-model:value="form.agencyBookingNumber" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Travel Agency Name')" name="agencyName">
      <a-input v-model:value="form.agencyName" :size="size" />
    </a-form-item>
    <a-form-item
      v-if="value.property.useRoomType"
      class="column is-12"
      :label="$t('Room Type Name')"
      name="roomCode"
    >
      <InputRoomType
        v-model:value="roomTypeId"
        :property-id="value.propertyId"
        @change="setRoomType"
      />
    </a-form-item>
    <a-form-item
      v-if="value.property.useRoomType"
      class="column is-12"
      :label="$t('Room Number')"
      name="roomNumber"
    >
      <InputRoomNumber
        v-model:value="form.roomNumber"
        :property-id="value.propertyId"
        :room-type-id="roomTypeId"
      />
    </a-form-item>
    <a-form-item
      v-if="!value.property.useRoomType"
      class="column is-12"
      :label="$t('Room')"
      name="roomCode"
    >
      <InputRoom
        v-model:value="form.roomCode"
        return-value="code"
        :property-id="value.propertyId"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check In Date')" name="checkin">
      <a-date-picker
        v-model:value="form.checkin"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Check Out Date')" name="checkout">
      <a-date-picker
        v-model:value="form.checkout"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Basic Information": "Edit Basic Information"
  },
  "ja": {
    "Edit Basic Information": "基本宿泊情報を編集"
  }
}
</i18n>

<script>
import edit from '@/views/reservations/mixins/edit';
import InputRoomType from '@/components/InputRoomType';
import InputRoom from '@/components/InputRoom';
import InputRoomNumber from '@/components/InputRoomNumber';

export default {
  name: 'EditBasicInfo',
  components: {
    InputRoomType,
    InputRoomNumber,
    InputRoom,
  },
  mixins: [edit],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      form: {
        agencyBookingNumber: undefined,
        agencyName: undefined,
        roomCode: undefined,
        checkin: undefined,
        checkout: undefined,
        roomNumber: undefined,
      },
      rules: {},
      size: 'large',
      loading: false,
      roomType: {},
      roomTypeId: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv && nv.details) {
          const keys = Object.keys(this.form);
          const { checkin, checkout, property } = nv;
          const details = nv.details || {};
          const basicInfo = details.basicInfo || {};

          const reservedRoom = nv.reservedRoom || {};
          const room = reservedRoom.room || {};
          const roomType = reservedRoom.roomType || {};
          const roomCode = property.useRoomType
            ? roomType.neppanRoomType
            : room.code;

          this.roomTypeId = roomType.id;

          keys.forEach((k) => {
            if (k === 'checkin') this.form[k] = checkin;
            else if (k === 'checkout') this.form[k] = checkout;
            else if (k === 'roomNumber') this.form[k] = room[k];
            else if (k === 'roomCode') this.form[k] = roomCode;
            else this.form[k] = basicInfo[k];
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        const {
          roomCode, roomNumber, checkin, checkout, ...rest
        } = this.form;
        const payloadDetails = {
          checkinCheckout: {
            checkin,
            checkout,
          },
          ...rest,
        };
        const payloadReservedRoom = {
          reservedRoom: {
            roomCode,
            roomNumber,
          },
        };

        await this.editReservedRooms(this.value, payloadReservedRoom);
        await this.editDetails(this.value, payloadDetails, 'basicInfo');
        this.$emit('close');
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    setRoomType(id, roomType) {
      this.form.roomCode = roomType.neppanRoomType;
    },
  },
};
</script>
