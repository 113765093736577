<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Charge') }}
      </h3>
    </div>

    <a-form-item
      class="column is-12"
      :label="$t('Total Accomodation Charge')"
      name="totalAccommodationCharge"
    >
      <a-input-number
        v-model:value="form.totalAccommodationCharge"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Point Used')"
      name="totalAccommodationDecleasePoints"
    >
      <a-input-number
        v-model:value="form.totalAccommodationDecleasePoints"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Guest Payment')" name="payment">
      <a-input v-model:value="form.payment" style="width: 100%" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Payment Method')" name="paymentMethod">
      <a-input v-model:value="form.paymentMethod" style="width: 100%" :size="size" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Charge": "Edit Charge"
  },
  "ja": {
    "Edit Charge": "宿泊費の編集"
  }
}
</i18n>

<script>
import edit from '@/views/reservations/mixins/edit';

export default {
  name: 'EditCharge',
  mixins: [edit],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      size: 'large',
      loading: false,
      form: {
        totalAccommodationCharge: undefined,
        totalAccommodationDecleasePoints: undefined,
        payment: undefined,
        paymentMethod: undefined,
      },
      rules: {},
      oldData: undefined,
      oldMember: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        const { details } = nv;
        const basicRateInfo = details.basicRateInfo || {};
        const member = (details && details.member) || {};

        this.oldData = basicRateInfo;
        this.oldMember = member;

        keys.forEach((k) => {
          if (k === 'totalAccommodationDecleasePoints') this.form[k] = member[k];
          else this.form[k] = basicRateInfo[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;

        const { totalAccommodationDecleasePoints, ...rest } = this.form;
        const payload = {
          details: {
            basicRateInfo: {
              ...this.oldData,
              ...rest,
            },
            member: {
              ...this.oldMember,
              totalAccommodationDecleasePoints,
            },
          },
        };

        await this.editDetails(this.value, payload);
        this.loading = false;
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
